<template>
  <!-- 大屏上侧 -->
  <div class="viewport_cs">
    <div class="orderTop">
      <span>政府采购项目总数据</span>
    </div>
    <div class="closeImage" @click="back()">
      <img src="@/image/twoThreeImgs/icon_lefts.png" alt="" />
    </div>
    <div class="viewport">
      <div class="search">
        <div class="contract_choice">
          <span> 合同选择 </span>
          <el-select
            v-model="selectContract"
            multiple
            placeholder="全部合同"
            collapse-tags
            @change="contractChange"
            popper-class="popper-c"
          >
            <el-option
              v-for="item in contractOptions"
              :key="item.contractGlobalId"
              :label="item.contractName"
              :value="item.contractGlobalId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="address">
          <span> 合同区域 </span>
          <div class="city">云南省</div>
          <div class="city">红河州</div>
          <div class="city">蒙自市</div>
          <!-- <div class="area">
              <el-select v-model="value1" multiple placeholder="请选择区/县">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
          <div class="street">
            <el-select
              v-model="street"
              collapse-tags
              multiple
              placeholder="请选择街道/村镇"
              @change="streetChange"
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.areaCode"
                :label="item.areaName"
                :value="item.areaCode"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="service_time">
          <span>服务时间</span>
          <el-date-picker
            v-model="serviceTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="button">
          <div class="submit" @click="submit">查询</div>
          <div class="reset" @click="reset">重置</div>
        </div>
      </div>
      <div class="contractAccount">
        <ul>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.num
            }}</span>
            <span class="contractAccount-name">合同数量</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.totalAmount
            }}</span>
            <span class="contractAccount-name">合同总金额</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.remainingAmount
            }}</span>
            <span class="contractAccount-name">合同剩余金额</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.amountServed
            }}</span>
            <span class="contractAccount-name">服务金额</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.totalNumber
            }}</span>
            <span class="contractAccount-name">合同总人数</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.peopleServedNumber
            }}</span>
            <span class="contractAccount-name">服务人数</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.personTimeServed
            }}</span>
            <span class="contractAccount-name">服务人次</span>
          </li>
          <li>
            <span class="contractAccount-amount">{{
              contractMessage.progressSpeed
            }}</span>
            <span class="contractAccount-name">总体进度</span>
          </li>
        </ul>
      </div>

      <div class="order">
        <div class="orderList">
          <div class="orderTitle">
            <span> 订单列表 </span>
          </div>
          <div class="orderTable">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{
                background: '#020328',
                color: '#538FBD',
              }"
              @row-click="rowClick"
              :row-style="tableRowStyle"
              :row-class-name="tableRowClassName"
            >
              <el-table-column prop="orderNo" label="订单编号" width="250">
              </el-table-column>
              <el-table-column prop="customerName" label="老人姓名" width="100">
              </el-table-column>
              <el-table-column prop="contractName" label="所属合同">
              </el-table-column>
              <el-table-column
                prop="appointmentTime"
                label="服务时间"
                width="180"
              >
              </el-table-column>
              <el-table-column prop="payAmount" label="订单金额" width="100">
                <template slot-scope="scope">
                  ¥{{ scope.row.payAmount }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="page">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="10"
              layout="total, prev, pager, next, jumper"
              :total="total"
              background
            >
            </el-pagination>
          </div>
        </div>
        <div class="orderDetail">
          <div class="orderDetailBox">
            <div class="orderTitle">
              <span> 订单详情 </span>
            </div>
            <div class="customer">
              <div class="customer_message">
                <div class="customer_message_left">
                  <div>
                    老人姓名：
                    <span>{{ orderDetailMessage.customerName }}</span>
                  </div>
                  <div>
                    身份证号：
                    <span>{{ orderDetailMessage.customerIdCard }}</span>
                  </div>
                </div>
                <div class="customer_message_right">
                  <div>
                    年龄：
                    <span>{{ num_page }}</span>
                  </div>
                </div>
              </div>
              <div class="government_message">
                <div class="government_message_left">
                  <div>政府补助金额</div>
                  <div>¥{{ subsidyAmount }}</div>
                </div>
                <div class="government_message_right">
                  <div>补助剩余金额</div>
                  <div>¥{{ governmentAmount }}</div>
                </div>
              </div>
            </div>
            <div class="service_detail">
              <div class="service_detail_title">服务详情</div>
              <div class="service_line">
                <div class="service_line-special">
                  <div class="service_line_label">服务时长：</div>
                  <div>{{ serviceItems.serviceDuration }} 分钟</div>
                </div>
                <div class="service_line-special">
                  <div class="service_line_label">服务人员：</div>
                  <div>
                    {{ serviceItems.staffName }}
                  </div>
                </div>
              </div>
              <div class="service_line">
                <div class="service_line_label">服务地址：</div>
                <div>{{ serviceItems.serviceAddress }}</div>
              </div>
              <div class="service_line">
                <div class="service_line_labels">服务项目：</div>
                <div style="flex: 1; overflow: hidden">
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane
                      :label="item.serviceName"
                      :name="item.itemNo"
                      v-for="(item, index) in orderDetailMessage.items"
                      :key="index"
                    ></el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="service_line">
                <div class="service_line_label">开始服务图片：</div>
                <div class="img_swiper">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, index) in beginImgUrls"
                        :key="index"
                      >
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="item"
                          :preview-src-list="beginImgUrls"
                        >
                        </el-image>
                      </div>
                    </div>
                    <!-- 如果需要导航按钮 -->
                    <div class="swiper-button-prev" slot="button-prev">
                      <img src="@/image/twoThreeImgs//icon_left.png" alt="" />
                    </div>
                    <div class="swiper-button-next" slot="button-next">
                      <img src="@/image/twoThreeImgs/icon_right.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="service_line">
                <div class="service_line_label">完成服务图片：</div>
                <div class="img_swiper">
                  <div class="swiper-container">
                    <div class="swiper-wrapper">
                      <div
                        class="swiper-slide"
                        v-for="(item, index) in endImgUrls"
                        :key="index"
                      >
                        <el-image
                          style="width: 100%; height: 100%"
                          :src="item"
                          :preview-src-list="endImgUrls"
                        >
                        </el-image>
                      </div>
                    </div>
                    <!-- 如果需要导航按钮 -->
                    <div class="swiper-button-prev" slot="button-prev">
                      <img src="@/image/twoThreeImgs/icon_left.png" alt="" />
                    </div>
                    <div class="swiper-button-next" slot="button-next">
                      <img src="@/image/twoThreeImgs/icon_right.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="service_line">
                <div class="service_line_label">服务满意度：</div>
                <div v-if="orderDetailMessage.score">
                  <el-rate
                    v-model="orderDetailMessage.score"
                    show-text
                    disabled
                    :texts="text"
                  >
                  </el-rate>
                </div>
              </div>
              <div class="service_line">
                <div class="service_line_label">评价视频：</div>
                <div v-if="orderDetailMessage.evaluateVideoUrl">
                  <video
                    :src="orderDetailMessage.evaluateVideoUrl"
                    width="200"
                    height="125"
                    controls
                    v-if="flag"
                  ></video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import {
  getOrderList,
  getOrderDetail,
  getContractMessage,
  getContractList,
  getAreaLists,
  getOrderinfo,
} from '@/api/districtCounty/middle.js'
export default {
  components: {
    Swiper,
  },
  // 注册组件
  data() {
    return {
      num_page: '',
      data_time: '',
      selectContract: [], //合同选择
      street: [], // 街道
      activeName: '',
      //   服务项目选中
      selectDate: '',
      contractOptions: [], //合同下拉框数据
      areaOptions: [], // 街道下拉框数据
      serviceTime: [],
      governmentAmount: '', //政府补助金额
      subsidyAmount: '', //补助剩余金额
      text: ['非常差', '差', '一般', '满意', '非常满意'],
      orderDetailMessage: {
        customerName: '',
        age: '',
        idCard: '',
        subsidyAmount: '',
        remainAmount: '',
        score: null,
        evaluateVideoUrl: '',
        items: [],
      },
      serviceItems: {
        serviceDuration: '',
        staffName: '',
        serviceAddress: '',
        itemNo: '',
        endImgUrls: [],
        beginImgUrls: [],
      },
      endImgUrls: [],
      beginImgUrls: [],
      //   日期只能选择90天范围
      pickerOptions: {
        disabledDate: function (time) {
          if (this.selectDate) {
            let deff = 90 * 24 * 60 * 60 * 1000
            let nowDate = this.selectDate.getTime()
            return (
              time.getTime() > nowDate + deff || time.getTime() < nowDate - deff
            )
          }
          return false
        }.bind(this),
        onPick: (date) => {
          // 如果只选择一个则保存至selectDate 否则selectDate 为空
          if (date.minDate && !date.maxDate) {
            this.selectDate = date.minDate
          } else {
            this.selectDate = null
          }
        },
      },
      flag: false,
      tableData: [],
      getIndex: 0,
      row: {},
      code: '',
      page: 1,
      perPage: 10,
      total: 1,
      contractMessage: {
        num: '',
        totalAmount: '',
        remainingAmount: '',
        amountServed: '',
        additionalAmount: '',
        totalNumber: '',
        personTimeServed: '',
        progressSpeed: '',
      },
    }
  },
  computed: {},
  created() {
    this.choiceTime(-365)
    this.code = this.$route.query.districtCode
    this.getData()
    this.getOptions()
    this.getContractMessage()
    let date = new Date()
    this.data_time = date.getFullYear()
  },
  mounted() {
    this.useSwiper()
  },
  methods: {
    choiceTime(num) {
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1
      const date = currentDate.getDate()
      this.serviceTime = [
        this.GetDateTime(num),
        year + '-' + month + '-' + date,
      ]
    },
    useSwiper() {
      new Swiper('.swiper-container', {
        //direction: 'vertical', // 垂直切换选项
        //mousewheel: true, //滚轮
        autoplay: false,
        loop: false, // 循环模式选项
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 5,
        spaceBetween: 20,
      })
    },
    GetDateTime(dayNum) {
      var dateDay = new Date()
      dateDay.setDate(dateDay.getDate() + dayNum) //获取dayNum天后的日期
      console.log(dateDay)
      var y = dateDay.getFullYear()
      var m =
        dateDay.getMonth() + 1 < 10
          ? '0' + (dateDay.getMonth() + 1)
          : dateDay.getMonth() + 1 //获取当前月份的日期，不足10补0
      var d =
        dateDay.getDate() < 10 ? '0' + dateDay.getDate() : dateDay.getDate() //获取当前几号，不足10补0
      return y + '-' + m + '-' + d
    },

    // 获取下拉选择框里面的数据
    getOptions() {
      const data = {
        districtCode: this.code,
      }
      getContractList(data).then((res) => {
        if (res.data.code == 0) {
          this.contractOptions = res.data.data
        }
      })
      getAreaLists(this.code).then((res) => {
        if (res.data.code == 0) {
          console.log(this.areaOptions)
          this.areaOptions = res.data.data
        }
      })
    },
    getOrderinfo() {
      getOrderinfo(this.row.contractGlobalId, this.row.customerGlobalId).then(
        (res) => {
          if (res.data.code == 0) {
            this.governmentAmount = res.data.data.governmentAmount
            this.subsidyAmount = res.data.data.subsidyAmount
          }
        }
      )
    },
    // 获取初始化数据
    getData(data) {
      let list = {
        page: this.page,
        perPage: this.perPage,
        districtCode: this.code,
        provinceCode: '530000',
        cityCode: '532500',
      }
      list = { ...list, ...data }
      // 分页数据
      getOrderList(list).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data
          this.total = res.data.total
          if (this.tableData != null) {
            if (this.tableData.length > 0) {
              this.row = this.tableData[0]
              this.getOrderDetail()
              this.getOrderinfo()
            } else {
              this.orderDetailMessage = {
                customerName: '',
                age: '',
                idCard: '',
                subsidyAmount: '',
                remainAmount: '',
                score: null,
                evaluateVideoUrl: '',
                items: [],
              }
              this.serviceItems = {
                serviceDuration: '',
                staffName: '',
                serviceAddress: '',
                itemNo: '',
                endImgUrls: [],
                beginImgUrls: [],
              }
              this.endImgUrls = []
              this.beginImgUrls = []
            }
          }
        }
      })
    },
    getContractMessage(data) {
      let list = {
        districtCode: this.code,
      }
      list = { ...list, ...data }
      getContractMessage(list).then((res) => {
        if (res.data.code == 0) {
          this.contractMessage = res.data.data
        }
      })
    },
    GetDateTime(dayNum) {
      var dateDay = new Date()
      dateDay.setDate(dateDay.getDate() + dayNum) //获取dayNum天后的日期
      console.log(dateDay)
      var y = dateDay.getFullYear()
      var m =
        dateDay.getMonth() + 1 < 10
          ? '0' + (dateDay.getMonth() + 1)
          : dateDay.getMonth() + 1 //获取当前月份的日期，不足10补0
      var d =
        dateDay.getDate() < 10 ? '0' + dateDay.getDate() : dateDay.getDate() //获取当前几号，不足10补0
      return y + '-' + m + '-' + d
    },
    getOrderDetail() {
      console.log(this.row)
      getOrderDetail(this.row.orderNo).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data)
          this.orderDetailMessage = res.data.data
          this.num_page = this.data_time - this.orderDetailMessage.customerIdCard.substring(6, 10)
          this.$nextTick(() => {
            this.flag = true
          })
          if (res.data.data.items != null) {
            if (res.data.data.items.length > 0) {
              this.serviceItems = res.data.data.items[0]
              this.activeName = res.data.data.items[0].itemNo
              if (this.serviceItems.beginImgUrls != null) {
                this.beginImgUrls = this.serviceItems.beginImgUrls.split(',')
              } else {
                this.beginImgUrls = []
              }
              if (this.serviceItems.endImgUrls != null) {
                this.endImgUrls = this.serviceItems.endImgUrls.split(',')
              } else {
                this.endImgUrls = []
              }
              this.$nextTick(() => {
                this.useSwiper()
              })
            }
          }
        }
      })
    },
    reset() {
      this.selectContract = []
      this.street = []
      this.choiceTime(-365)
      this.page = 1
      this.getIndex = 0
      this.getData()
      this.getContractMessage()
    },
    submit() {
      this.page = 1
      console.log(this.serviceTime)
      const data = {
        contractGlobalIds:
          this.selectContract.length == 0 ? null : this.selectContract,
        streetCodes: this.street.length == 0 ? null : this.street,
        appointBeginTime:
          this.serviceTime == null
            ? null
            : this.serviceTime[0]
            ? this.serviceTime[0] + ' 00:00:00'
            : null,
        appointEndTime:
          this.serviceTime == null
            ? null
            : this.serviceTime[1]
            ? this.serviceTime[1] + ' 00:00:00'
            : null,
      }
      const list = {
        contractGlobalIds:
          this.selectContract.length == 0 ? null : this.selectContract,
        streetCode: this.street.length == 0 ? null : this.street,
        startAt:
          this.serviceTime == null
            ? null
            : this.serviceTime[0]
            ? this.serviceTime[0] + ' 00:00:00'
            : null,
        endAt:
          this.serviceTime == null
            ? null
            : this.serviceTime[1]
            ? this.serviceTime[1] + ' 00:00:00'
            : null,
      }
      console.log(data, list)
      this.getData(data)
      this.getIndex = 0
      this.getContractMessage(list)
    },
    changeCurrent() {
      console.log(this.serviceTime)
      const data = {
        contractGlobalIds:
          this.selectContract.length == 0 ? null : this.selectContract,
        streetCodes: this.street.length == 0 ? null : this.street,
        appointBeginTime:
          this.serviceTime == null
            ? null
            : this.serviceTime[0]
            ? this.serviceTime[0] + ' 00:00:00'
            : null,
        appointEndTime:
          this.serviceTime == null
            ? null
            : this.serviceTime[1]
            ? this.serviceTime[1] + ' 00:00:00'
            : null,
      }
      const list = {
        contractGlobalIds:
          this.selectContract.length == 0 ? null : this.selectContract,
        streetCode: this.street.length == 0 ? null : this.street,
        startAt:
          this.serviceTime == null
            ? null
            : this.serviceTime[0]
            ? this.serviceTime[0] + ' 00:00:00'
            : null,
        endAt:
          this.serviceTime == null
            ? null
            : this.serviceTime[1]
            ? this.serviceTime[1] + ' 00:00:00'
            : null,
      }
      console.log(data, list)
      this.getData(data)
      this.getIndex = 0
      this.getContractMessage(list)
    },
    contractChange() {
      this.street = []
    },
    streetChange() {
      this.selectContract = []
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进.row
      row.index = rowIndex
    },
    rowClick(row) {
      this.row = row
      this.getOrderDetail()
      this.getOrderinfo()
      console.log(this.row)
      this.getIndex = row.index
      console.log(this.getIndex)
    },
    tableRowStyle({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          background: 'rgba(255,158,20,.1)',
          'box-shadow': '0 0 0 0px #ff9e14,inset 0 0 10px #ff9e14',
          border: '1px solid rgba(255,158,20,.1)',
          color: 'rgb(255,158,20)',
        }
      }
    },
    handleCurrentChange(val) {
      this.page = val
      this.changeCurrent()
    },
    // 切换tabs
    handleClick(val) {
      const data = this.orderDetailMessage.items.filter((item) => {
        if (item.itemNo == val.name) {
          return item
        }
      })
      this.serviceItems = data[0]
      console.log(2222, this.serviceItems)
      if (this.serviceItems.beginImgUrls != null) {
        this.beginImgUrls = this.serviceItems.beginImgUrls.split(',')
      } else {
        this.beginImgUrls = []
      }
      if (this.serviceItems.endImgUrls != null) {
        this.endImgUrls = this.serviceItems.endImgUrls.split(',')
      } else {
        this.endImgUrls = []
      }
      this.$nextTick(() => {
        this.flag = true
        this.useSwiper()
      })
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.viewport_cs {
  background: url('../../../../image/twoThreeImgs/background.png') no-repeat 0 0 /
    contain;
  background-size: 100% 100%;
}
.viewport {
  min-height: 780px;
  padding: 0;
  max-width: 1848px;
  min-width: 780px;
  margin: 0 auto;
}
.closeImage {
  position: absolute;
  left: 0.66666667rem;
  top: 0.91666667rem;
  cursor: pointer;
}
.orderTop {
  background: url('../../../../image/twoThreeImgs/orderTops.png') 0 0 no-repeat;

  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1920/89;
  position: relative;
}
.orderTop > span {
  margin-top: 0.33333333rem;
  font-size: 1.33rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  background: linear-gradient(0deg, #ffffff 0%, #ffdf49 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.search {
  width: 100%;
  aspect-ratio: 1848/75;
  background: url('../../../../image/twoThreeImgs/bg_sousuo.png') no-repeat 0 0 /
    contain;
  margin-top: 22px;
  font-size: 0.58rem;
  display: flex;
  align-items: center;
  color: #4dc2ff;
  .contract_choice {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.33rem;
    .el-select {
      margin-left: 0.5rem;
    }
    color: #4dc2ff;
    ::v-deep .el-tag.el-tag--info {
      background: transparent;
      border: none;
      color: #4dc2ff;
    }
  }
  .address {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.5rem;
    span {
      margin-right: 0.5rem;
    }
    .city {
      width: 3.75rem;
      height: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('../../../../image/twoThreeImgs/bg_shengshi.png')
        no-repeat 0 0 / contain;
      &:nth-child(3) {
        margin-left: 0.29rem;
        margin-right: 0.29rem;
      }
    }
    .area {
      ::v-deep .el-input__inner {
        background: url('../../../../image/twoThreeImgs/input_quxian.png')
          no-repeat 0 0 / contain;
        border: none;
        width: 6.75rem;
        height: 1.92rem !important;
        &::placeholder {
          color: #4dc2ff;
        }
      }
    }
    .street {
      margin-left: 0.33rem;
      ::v-deep .el-input__inner {
        background: url('../../../../image/twoThreeImgs/input_jiedao.png')
          no-repeat 0 0 / contain;
        border: none;
        width: 10.08rem;
        height: 1.92rem !important;
        &::placeholder {
          color: #4dc2ff;
        }
      }
      ::v-deep .el-tag.el-tag--info {
        background: transparent;
        border: none;
        color: #4dc2ff;
      }
    }
  }
  .service_time {
    margin-left: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep .el-date-editor {
      margin-left: 0.5rem;
      background: url('../../../../image/twoThreeImgs/bg_timechoose.png')
        no-repeat 0 0 / contain;
      width: 13.42rem;
      height: 1.875rem;
      border: none;
      .el-range-input {
        background: transparent;
        &::placeholder {
          color: #4dc2ff;
        }
        color: #4dc2ff;
      }
      .el-range-separator {
        color: #4dc2ff;
        line-height: 1.6rem;
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .submit {
      height: 1.875rem;
      width: 4.25rem;
      margin-left: 0.5rem;
      background: url('../../../../image/twoThreeImgs/bg_button.png') no-repeat
        0 0 / contain;
      font-size: 0.67rem;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .reset {
      color: #ffffff;
      font-size: 0.67rem;
      width: 4.25rem;
      height: 1.875rem;
      margin-left: 0.33rem;
      background: url('../../../../image/twoThreeImgs/bg_button.png') no-repeat
        0 0 / contain;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.contractAccount {
  width: 100%;
  aspect-ratio: 1848/118;
  background: url('../../../../image/twoThreeImgs/bg_shuju.png') no-repeat 0 0 /
    cover;
  margin-top: 15px;
}

.contractAccount > ul {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contractAccount > ul > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}
.contractAccount-amount {
  font-size: 1.33rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ff9e14;
}
.contractAccount-name {
  font-size: 0.67rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #3c75b5;
}
.contract_choice {
  ::v-deep .el-input__inner {
    background: url('../../../../image/twoThreeImgs/input_hetong.png') no-repeat
      0 0 / cover;
    border: none;
    width: 13.42rem;
    height: 1.92rem !important;
    &::placeholder {
      color: #4dc2ff;
    }
  }
}
.order {
  width: 100%;
  aspect-ratio: 1848/728;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.7rem;
  margin-top: 0.67rem;
  box-sizing: border-box;
  .orderList {
    width: 62.22%;
    height: 100%;
    background: url('../../../../image/twoThreeImgs/bg_liebiao.png') center
      center no-repeat;
    display: flex;
    flex-direction: column;
    .orderTitle {
      margin: 0 auto;
      margin-top: 0.67rem;
      height: 1.71rem;
      width: 46.08rem;
      background: url('../../../../image/twoThreeImgs/bg_title_liebiao.png')
        left center no-repeat;
      display: flex;
      align-items: center;
      font-size: 0.83rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 0.625rem;
      span {
        margin-left: 2rem;
      }
    }
    .orderTable {
      width: 46.08rem;
      margin: 0 auto;
      ::v-deep .el-table,
      .el-table tr,
      .el-table td,
      .el-table th {
        background-color: transparent !important;
      }
      overflow: auto;
      flex: 1;
      .el-table {
        ::v-deep td {
          height: 2.2rem;
        }
      }
    }
    .page {
      ::v-deep .el-input__inner {
        background: transparent;
        border: 1px solid #00d5ff;
        color: #4dc2ff;
      }

      display: flex;
      justify-content: flex-end;
      padding: 0.5rem;
    }
  }
  .orderDetail {
    width: 36.85%;
    height: 100%;
    background: url('../../../../image/twoThreeImgs/bg_xiangqing.png') center
      center no-repeat;
    .orderDetailBox {
      width: 26.29rem;
      height: 100%;
      margin: 0 auto;
      padding-top: 0.67rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .orderTitle {
        height: 1.71rem;
        width: 100%;
        background: url('../../../../image/twoThreeImgs/bg_title_xiangqing.png')
          left center no-repeat;
        display: flex;
        align-items: center;
        font-size: 0.83rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        span {
          margin-left: 2rem;
        }
      }
      .customer {
        width: 100%;
        height: 7.375rem;
        display: flex;
        flex-direction: column;
        .customer_message {
          height: 3.42rem;
          width: 100%;
          border-bottom: 2px solid #0c6fa3;
          display: flex;
          .customer_message_left {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            font-size: 0.67rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0c6fa3;
            padding-left: 1.41rem;
            span {
              color: #4dc2ff;
            }
          }
          .customer_message_right {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 0.67rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #0c6fa3;
            padding-left: 2rem;
            padding-top: 0.65rem;
            span {
              color: #4dc2ff;
            }
          }
        }

        .government_message {
          flex: 1;
          border-bottom: 2px solid #0c6fa3;
          display: flex;
          .government_message_left {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            padding-left: 1.41rem;
            div {
              &:nth-child(1) {
                font-size: 0.67rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0c6fa3;
              }
              &:nth-child(2) {
                font-size: 1.33rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #4dc2ff;
              }
            }
          }
          .government_message_right {
            height: 100%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            padding-left: 2rem;
            div {
              &:nth-child(1) {
                font-size: 0.67rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0c6fa3;
              }
              &:nth-child(2) {
                font-size: 1.33rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ff9e14;
              }
            }
          }
        }
      }
      .service_detail {
        flex: 1;
        .service_detail_title {
          margin-top: 0.75rem;
          font-size: 0.67rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #6ecdff;
          margin-bottom: 0.58rem;
        }
        .service_line {
          margin-top: 0.55rem;
          font-size: 0.67rem;
          margin-bottom: 0.55rem;
          color: #4dc2ff;
          display: flex;
          .img_swiper {
            width: 21.6rem;
            height: 3rem;
            margin: auto;
            .swiper-container {
              height: 3rem;
              padding-left: 15px;
              padding-right: 20px;
            }
          }

          .service_line-special {
            display: flex;
            &:nth-child(2) {
              margin-left: 6.6rem;
            }
          }
          .service_line_labels {
            color: #0c6fa3;
            width: 4.78rem;
            text-align: right;
            float: left;
          }
          .service_line_label {
            color: #0c6fa3;
            width: 4.78rem;
            text-align: right;
          }
          ::v-deep .el-tabs__nav-next {
            line-height: 1rem;
          }
          ::v-deep .el-tabs__nav-prev {
            line-height: 1rem;
          }
          ::v-deep .el-tabs__item {
            font-size: 0.67rem;
            height: 1rem;
            line-height: 0.67rem;
            color: #4dc2ff;
          }

          ::v-deep .el-tabs__active-bar {
            background-color: #ff9e14 !important;
          }
          ::v-deep .is-active {
            color: #ff9e14;
          }
          ::v-deep .el-tabs__nav-wrap::after {
            position: static !important;
          }
          ::v-deep .el-tabs__header {
            margin: 0;
          }
        }
      }
    }
  }
}
::v-deep .el-rate__text {
  color: #fcc601 !important;
}

::v-deep .el-select-dropdown__list {
  background-color: #050f2d !important;
}

::v-deep .el-select-dropdown__item {
  background-color: #050f2d !important;
}
</style>
<style lang="scss">
// 时间选择器

.el-date-range-picker {
  background: url('../../../../image/twoThreeImgs/bg_rili.png') no-repeat;
  width: 26.4rem;
  height: 15rem;
  border: none;
  background-position: center 0.1rem;
  background-size: 105%;
}
.el-date-range-picker__content.is-left {
  border: none;
}
.el-date-table th {
  border-bottom: solid 1px #0d90ff;
  color: #538fbd;
}
.el-date-table td.next-month,
.el-date-table td.prev-month {
  color: rgba(77, 194, 255, 0.3);
}
.available {
  color: #4dc2ff;
}
.el-date-range-picker__header {
  color: #4dc2ff;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #ff9e14;
}

.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
  background: rgba(255, 158, 20, 0.25);
  opacity: 0.6;
  color: #ffffff !important;
}
.el-popper .popper__arrow::after {
  content: '';
  border-width: 0px;
}
.el-popper .popper__arrow {
  border-width: 0px;
}
.el-picker-panel__icon-btn {
  color: #4dc2ff;
}
.el-date-table td.disabled div {
  background-color: #0d90ff;
  opacity: 0.5;
}

/*table边框颜色*/
.el-table--border:after,
.el-table--group:after,
.el-table:before {
  background-color: #0d618d;
}
.el-table--border,
.el-table--group {
  border-color: #0d618d;
}
.el-table td,
.el-table th.is-leaf {
  border-bottom: 1px solid #0d618d !important;
}

.el-table--border th,
.el-table--border th.gutter:last-of-type {
  border-bottom: 1px solid #0d618d;
}

.el-table--border td,
.el-table--border th {
  border-right: 1px solid #0d618d;
}
.el-table th,
.el-table tr {
  background-color: transparent;
  color: #4dc2ff;
}

.warm-row {
  background-color: #ff9e14;
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent;
}
.el-table__row {
  //   background: url(../../imgs/mengzi/order/bg_shuju_selected.png) 0px 0px
  //     no-repeat;
  cursor: pointer;
}
.el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: transparent;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: rgba(255, 158, 20, 0.3);
  color: #ff9e14;
  box-shadow: '0 0 0 1px #ff9e14,inset 0 0 1px #ff9e14';
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: transparent;
  border: 1px solid #4dc2ff;
  color: #4dc2ff;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #ff9e14;
}
.el-pagination__total {
  color: #4dc2ff;
}
.el-pagination__jump {
  color: #4dc2ff;
}
.swiper-container {
  --swiper-navigation-size: 0;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: none;
}
.swiper-button-prev {
  position: absolute;
  left: 0px;
}
.swiper-button-next {
  position: absolute;
  right: -18px;
}
.swiper-button-prev,
.swiper-button-next {
  margin-top: -11px;
}

.el-select-dropdown__list {
  background-color: #050f2d;
}

.el-select-dropdown__item {
  background-color: #050f2d;
  color: #4dc2ff;
  border-radius: 3px;
  margin-left: 3px;
  margin-right: 3px;
}

.el-select-dropdown {
  border: 2px solid #0f1f4f;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #0f1f4f;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background: #0f1f4f;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background: #0f1f4f;
}
</style>
